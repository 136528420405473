import React, { useMemo, useState, useEffect } from 'react'
import '../../styles/virtualMachine.less'
import SEO from '../../components/SEO'
import { flatten, none, sort } from 'ramda'
import { SelectLocation, Table, Amount } from '@zenlayer/zen-design'
import '@zenlayer/zen-design/esm/select-location/style/index.less'
import '@zenlayer/zen-design/esm/table/style/index.less'
import '@zenlayer/zen-design/esm/amount/style/index.less'
import { ConsultationPopButton } from '../../components/form-biz'
import { SolutionTopBanner } from '../../components/block'
import Button from '../../components/ui/button'
import AnimationWrap from '../../components/block/AnimationWrap'
import { Map } from '../../pages-en/lp/baremetal'
import Footer from '../../components/footer'
const isIn = process.env.GATSBY_DOMAIN === 'in'
const static_data = {
  topBanner: {
    title: 'Virtual Machines',
    subtitle: 'Instant compute in 20+ countries',
    description:
      'Highly scalable virtual servers across Africa, Asia, Europe, Middle East, North & South America.',
    buttonText: 'Deploy now',
    buttonText2: 'Configure and price',
  },
}
const jumpToSignup = (utm_id?: string) => {
  window.open(
    `https://console.zenlayer.com/auth/signup/input?utm_source=google&utm_medium=cpc&utm_campaign=VM&utm_id=${
      utm_id || 'VM'
    }&referral=${encodeURIComponent(`${location.origin}/${location.pathname}`)}`
  )
}

const jumpToConsole = (url) => {
  if (isIn) {
    window.open('https://bit.ly/3UkqKuk')
  } else {
    window.open(url)
  }
}

const cities = [
  {
    code: 'AMS',
    city: 'Amsterdam',
    nameCn: '阿姆斯特丹',
    lat: 52.355016,
    lng: 4.960938,
    products: [
      'BMC_SERVER',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_CLOUD',
      'SDN_CLOUD',
      'VM_SERVER',
    ],
  },
  {
    code: 'ARN',
    city: 'Stockholm',
    nameCn: '斯德哥尔摩',
    lat: 59.422475,
    lng: 17.915882,
    products: ['BMC_SERVER'],
  },
  {
    code: 'ATH',
    city: 'Athens',
    nameCn: '雅典',
    lat: 38.033468,
    lng: 23.794555,
    products: [],
  },
  {
    code: 'ATL',
    city: 'Atlanta',
    nameCn: '亚特兰大',
    lat: 33.761799,
    lng: -84.391442,
    products: ['BMC_SERVER'],
  },
  {
    code: 'BBU',
    city: 'Bucharest',
    nameCn: '布加勒斯特',
    lat: 44.480808,
    lng: 26.115272,
    products: [],
  },
  {
    code: 'BEG',
    city: 'Belgrade',
    nameCn: '贝尔格莱德',
    lat: 44.811413,
    lng: 20.398691,
    products: [],
  },
  {
    code: 'BKK',
    city: 'Bangkok',
    nameCn: '曼谷',
    lat: 13.663605,
    lng: 100.651746,
    products: ['BMC_SERVER', 'SDN_VLL', 'SDN_VLL', 'SDN_CLOUD', 'SDN_CLOUD'],
  },
  {
    code: 'BOG',
    city: 'Bogotá',
    nameCn: '波哥大',
    lat: 4.680523,
    lng: -74.042689,
    products: ['SDN_VLL', 'SDN_CLOUD', 'BMC_SERVER'],
  },
  {
    code: 'CAI',
    city: 'Cairo',
    nameCn: '开罗',
    lat: 30.099009,
    lng: 31.173065,
    products: ['BMC_SERVER'],
  },
  {
    code: 'CEB',
    city: 'Lahug Cebu City',
    nameCn: '宿雾市',
    lat: 10.329499,
    lng: 123.907104,
    products: [],
  },
  {
    code: 'CHI',
    city: 'Chicago',
    nameCn: '芝加哥',
    lat: 42.001361,
    lng: -87.954778,
    products: ['BMC_SERVER', 'SDN_VLL', 'SDN_CLOUD'],
  },
  {
    code: 'COD',
    city: 'Toronto',
    nameCn: '多伦多',
    lat: 43.642972,
    lng: -79.609399,
    products: [],
  },
  {
    code: 'DAC',
    city: 'Dhaka',
    nameCn: '达卡',
    lat: 23.795967,
    lng: 90.413822,
    products: ['SDN_VLL', 'SDN_CLOUD', 'BMC_SERVER'],
  },
  {
    code: 'DAD',
    city: 'Da Nang',
    nameCn: '岘港',
    lat: 16.084892,
    lng: 108.118477,
    products: [],
  },
  {
    code: 'DFW',
    city: 'Dallas',
    nameCn: '达拉斯',
    lat: 32.800567,
    lng: -96.819472,
    products: ['BMC_SERVER', 'SDN_VLL', 'SDN_CLOUD'],
  },
  {
    code: 'DMK',
    city: 'Pathum Thani',
    nameCn: '巴吞他尼',
    lat: 13.5445,
    lng: 100.3624,
    products: [],
  },
  {
    code: 'DOH',
    city: 'Doha',
    nameCn: '多哈',
    lat: 25.311022,
    lng: 51.504003,
    products: ['BMC_SERVER'],
  },
  {
    code: 'DUB',
    city: 'Dublin',
    nameCn: '都柏林',
    lat: 53.411987,
    lng: -6.35168,
    products: [],
  },
  {
    code: 'DVO',
    city: 'Davao',
    nameCn: '达沃',
    lat: 7.0167,
    lng: 125.4935,
    products: [],
  },
  {
    code: 'DXB',
    city: 'Dubai',
    nameCn: '迪拜',
    lat: 25.029016,
    lng: 55.186429,
    products: ['BMC_SERVER', 'SDN_VLL', 'SDN_CLOUD'],
  },
  {
    code: 'EZE',
    city: 'Buenos Aires',
    nameCn: '布宜诺斯艾利斯',
    lat: -34.590056,
    lng: -58.469404,
    products: ['SDN_VLL', 'SDN_CLOUD', 'BMC_SERVER'],
  },
  {
    code: 'FJR',
    city: 'Fujairah City',
    nameCn: '富查伊拉',
    lat: 25.149834,
    lng: 56.35385,
    products: ['BMC_SERVER', 'SDN_VLL', 'SDN_CLOUD'],
  },
  {
    code: 'FOR',
    city: 'Fortaleza',
    nameCn: '福塔雷萨',
    lat: -3.808412,
    lng: -38.527385,
    products: ['BMC_SERVER'],
  },
  {
    code: 'FRA',
    city: 'Frankfurt',
    nameCn: '法兰克福',
    lat: 50.120188,
    lng: 8.73542,
    products: [
      'BMC_SERVER',
      'VM_SERVER',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_CLOUD',
      'SDN_CLOUD',
      'SDN_CLOUD',
    ],
  },
  {
    code: 'GIG',
    city: 'Castilho',
    nameCn: '卡斯蒂略',
    lat: -22.868957,
    lng: -43.272929,
    products: ['BMC_SERVER'],
  },
  {
    code: 'HAN',
    city: 'Hanoi',
    nameCn: '河内',
    lat: 21.007838,
    lng: 105.528894,
    products: [
      'BMC_SERVER',
      'VM_SERVER',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_CLOUD',
      'SDN_CLOUD',
    ],
  },
  {
    code: 'HKG',
    city: 'Hong Kong',
    nameCn: '香港',
    lat: 22.39836,
    lng: 114.190563,
    products: [
      'BMC_SERVER',
      'VM_SERVER',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_CLOUD',
      'SDN_CLOUD',
      'SDN_CLOUD',
      'SDN_CLOUD',
      'SDN_CLOUD',
      'SDN_CLOUD',
    ],
  },
  {
    code: 'IAD',
    city: 'Washington, D.C.',
    nameCn: '华盛顿 D.C.',
    lat: 38.949283,
    lng: -77.364599,
    products: [
      'BMC_SERVER',
      'VM_SERVER',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_CLOUD',
      'SDN_CLOUD',
    ],
  },
  {
    code: 'ISB',
    city: 'Islamabad',
    nameCn: '伊斯兰堡',
    lat: 33.726033,
    lng: 73.082879,
    products: ['BMC_SERVER', 'SDN_VLL', 'SDN_CLOUD'],
  },
  {
    code: 'IST',
    city: 'Istanbul',
    nameCn: '伊斯坦布尔',
    lat: 40.996026,
    lng: 29.166645,
    products: [
      'BMC_SERVER',
      'VM_SERVER',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_CLOUD',
      'SDN_CLOUD',
    ],
  },
  {
    code: 'JED',
    city: 'Jeddah',
    nameCn: '吉达',
    lat: 21.465366,
    lng: 39.208277,
    products: ['VM_SERVER'],
  },
  {
    code: 'JKT',
    city: 'Jakarta',
    nameCn: '雅加达',
    lat: -6.297055,
    lng: 106.66815,
    products: [
      'BMC_SERVER',
      'VM_SERVER',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_CLOUD',
      'SDN_CLOUD',
      'SDN_CLOUD',
      'SDN_CLOUD',
      'SDN_CLOUD',
    ],
  },
  {
    code: 'JNB',
    city: 'Johannesburg',
    nameCn: '约翰内斯堡',
    lat: -26.137414,
    lng: 28.197624,
    products: ['BMC_SERVER', 'SDN_VLL', 'SDN_CLOUD'],
  },
  {
    code: 'KHH',
    city: 'Kaohsiung City',
    nameCn: '高雄',
    lat: 22.628828,
    lng: 120.301899,
    products: [],
  },
  {
    code: 'KHI',
    city: 'Karachi',
    nameCn: '卡拉奇',
    lat: 24.828026,
    lng: 67.097844,
    products: ['BMC_SERVER', 'SDN_VLL', 'SDN_CLOUD'],
  },
  {
    code: 'KNO',
    city: 'Medan City',
    nameCn: '棉兰市',
    lat: 3.592696,
    lng: 98.676946,
    products: [],
  },
  {
    code: 'KUL',
    city: 'Kuala Lumpur',
    nameCn: '吉隆坡',
    lat: 3.150052,
    lng: 101.706431,
    products: ['BMC_SERVER', 'SDN_VLL', 'SDN_CLOUD'],
  },
  {
    code: 'LAX',
    city: 'Los Angeles',
    nameCn: '洛杉矶',
    lat: 34.058295,
    lng: -118.234673,
    products: [
      'BMC_SERVER',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_CLOUD',
      'SDN_CLOUD',
      'SDN_CLOUD',
      'VM_SERVER',
    ],
  },
  {
    code: 'LIM',
    city: 'Lima',
    nameCn: '利马',
    lat: -12.089138,
    lng: -76.975562,
    products: ['VM_SERVER'],
  },
  {
    code: 'LIS',
    city: 'Lisbon',
    nameCn: '里斯本',
    lat: 38.787987,
    lng: -9.123361,
    products: [],
  },
  {
    code: 'LON',
    city: 'London',
    nameCn: '伦敦',
    lat: 51.49992,
    lng: -0.015009,
    products: [
      'BMC_SERVER',
      'VM_SERVER',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_CLOUD',
      'SDN_CLOUD',
    ],
  },
  {
    code: 'LOS',
    city: 'Lagos',
    nameCn: '拉各斯',
    lat: 6.450773,
    lng: 3.592092,
    products: ['BMC_SERVER', 'SDN_VLL', 'SDN_CLOUD'],
  },
  {
    code: 'MAD',
    city: 'Madrid',
    nameCn: '马德里',
    lat: 40.43993,
    lng: -3.621013,
    products: ['BMC_SERVER', 'VM_SERVER', 'SDN_VLL', 'SDN_CLOUD'],
  },
  {
    code: 'MEL',
    city: 'Melbourne',
    nameCn: '墨尔本',
    lat: -37.821618,
    lng: 144.916249,
    products: ['VM_SERVER', 'SDN_VLL', 'SDN_CLOUD'],
  },
  {
    code: 'MEX',
    city: 'Mexico City',
    nameCn: '墨西哥城',
    lat: 19.628305,
    lng: -99.152894,
    products: ['BMC_SERVER', 'VM_SERVER', 'SDN_VLL', 'SDN_CLOUD'],
  },
  {
    code: 'MIA',
    city: 'Miami',
    nameCn: '迈阿密',
    lat: 25.797933,
    lng: -80.230116,
    products: ['BMC_SERVER', 'SDN_VLL', 'SDN_VLL', 'SDN_CLOUD', 'SDN_CLOUD'],
  },
  {
    code: 'MNL',
    city: 'Manila',
    nameCn: '马尼拉',
    lat: 14.564484,
    lng: 121.020179,
    products: [
      'BMC_SERVER',
      'VM_SERVER',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_CLOUD',
      'SDN_CLOUD',
      'SDN_CLOUD',
      'SDN_CLOUD',
    ],
  },
  {
    code: 'MOW',
    city: 'Moscow',
    nameCn: '莫斯科',
    lat: 55.886734,
    lng: 37.513224,
    products: [
      'BMC_SERVER',
      'VM_SERVER',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_CLOUD',
      'SDN_CLOUD',
      'SDN_CLOUD',
    ],
  },
  {
    code: 'MRS',
    city: 'Marseille',
    nameCn: '马赛',
    lat: 43.336735,
    lng: 5.353049,
    products: ['SDN_VLL', 'SDN_CLOUD'],
  },
  {
    code: 'MXP',
    city: 'Milan',
    nameCn: '米兰',
    lat: 45.448729,
    lng: 9.147479,
    products: ['BMC_SERVER'],
  },
  {
    code: 'NBO',
    city: 'Nairobi',
    nameCn: '内罗毕',
    lat: -1.329903,
    lng: 36.86619,
    products: ['VM_SERVER'],
  },
  {
    code: 'NYC',
    city: 'New York City',
    nameCn: '纽约',
    lat: 40.718651,
    lng: -74.008261,
    products: ['BMC_SERVER', 'SDN_VLL', 'SDN_CLOUD'],
  },
  {
    code: 'OSA',
    city: 'Osaka',
    nameCn: '大阪',
    lat: 34.675997,
    lng: 135.495522,
    products: ['BMC_SERVER'],
  },
  {
    code: 'PAR',
    city: 'Paris',
    nameCn: '巴黎',
    lat: 48.934199,
    lng: 2.32418,
    products: ['BMC_SERVER', 'VM_SERVER'],
  },
  {
    code: 'PKU',
    city: 'Pekanbaru',
    nameCn: '北干巴鲁',
    lat: 0.471831,
    lng: 101.395118,
    products: [],
  },
  {
    code: 'PNH',
    city: 'Phnom Penh',
    nameCn: '金边',
    lat: 11.556178,
    lng: 104.843524,
    products: [],
  },
  {
    code: 'PRG',
    city: 'Prague',
    nameCn: '布拉格',
    lat: 50.060462,
    lng: 14.483381,
    products: [],
  },
  {
    code: 'RGN',
    city: 'Yangon',
    nameCn: '仰光',
    lat: 16.812021,
    lng: 96.134693,
    products: ['BMC_SERVER', 'SDN_VLL', 'SDN_CLOUD'],
  },
  {
    code: 'RUH',
    city: 'Riyadh',
    nameCn: '利雅得',
    lat: 24.718313,
    lng: 46.646568,
    products: ['VM_SERVER'],
  },
  {
    code: 'SAO',
    city: 'São Paulo',
    nameCn: '圣保罗',
    lat: -23.465833,
    lng: -46.863129,
    products: [
      'BMC_SERVER',
      'VM_SERVER',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_CLOUD',
      'SDN_CLOUD',
      'SDN_CLOUD',
    ],
  },
  {
    code: 'SCL',
    city: 'Santiago',
    nameCn: '圣地亚哥',
    lat: -33.358302,
    lng: -70.677126,
    products: ['SDN_VLL', 'SDN_CLOUD', 'BMC_SERVER'],
  },
  {
    code: 'SEA',
    city: 'Seattle',
    nameCn: '西雅图',
    lat: 47.493194,
    lng: -122.288909,
    products: ['SDN_VLL', 'SDN_VLL', 'SDN_CLOUD', 'SDN_CLOUD', 'BMC_SERVER'],
  },
  {
    code: 'SEL',
    city: 'Seoul',
    nameCn: '首尔',
    lat: 37.335097,
    lng: 127.110334,
    products: [
      'BMC_SERVER',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_CLOUD',
      'SDN_CLOUD',
      'SDN_CLOUD',
      'SDN_CLOUD',
      'SDN_CLOUD',
    ],
  },
  {
    code: 'SGN',
    city: 'Ho Chi Minh City',
    nameCn: '胡志明',
    lat: 10.753972,
    lng: 106.74064,
    products: [
      'BMC_SERVER',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_CLOUD',
      'SDN_CLOUD',
      'SDN_CLOUD',
    ],
  },
  {
    code: 'SIN',
    city: 'Singapore',
    nameCn: '新加坡',
    lat: 1.31712,
    lng: 103.701551,
    products: [
      'BMC_SERVER',
      'VM_SERVER',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_CLOUD',
      'SDN_CLOUD',
      'SDN_CLOUD',
      'SDN_CLOUD',
      'SDN_CLOUD',
      'SDN_CLOUD',
      'SDN_CLOUD',
    ],
  },
  {
    code: 'SJC',
    city: 'San Jose',
    nameCn: '圣何塞',
    lat: 37.378419,
    lng: -121.955008,
    products: ['BMC_SERVER', 'SDN_VLL', 'SDN_VLL', 'SDN_CLOUD', 'SDN_CLOUD'],
  },
  {
    code: 'SOF',
    city: 'Sofia',
    nameCn: '索菲亚',
    lat: 42.671886,
    lng: 23.407631,
    products: ['SDN_VLL', 'SDN_CLOUD', 'VM_SERVER'],
  },
  {
    code: 'SUB',
    city: 'Surabaya',
    nameCn: '泗水',
    lat: -7.27619,
    lng: 112.745206,
    products: [],
  },
  {
    code: 'SYD',
    city: 'Sydney',
    nameCn: '悉尼',
    lat: -33.918442,
    lng: 151.189061,
    products: ['SDN_VLL', 'SDN_CLOUD'],
  },
  {
    code: 'TLV',
    city: 'Tel Aviv',
    nameCn: '特拉维夫',
    lat: 32.108222,
    lng: 34.969213,
    products: ['VM_SERVER'],
  },
  {
    code: 'TPE',
    city: 'Taipei',
    nameCn: '台北',
    lat: 25.071115,
    lng: 121.58209,
    products: [
      'BMC_SERVER',
      'VM_SERVER',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_CLOUD',
      'SDN_CLOUD',
    ],
  },
  {
    code: 'TYO',
    city: 'Tokyo',
    nameCn: '东京',
    lat: 35.622458,
    lng: 139.747733,
    products: [
      'BMC_SERVER',
      'VM_SERVER',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_VLL',
      'SDN_CLOUD',
      'SDN_CLOUD',
      'SDN_CLOUD',
    ],
  },
  {
    code: 'VTE',
    city: 'Laos',
    nameCn: '老挝',
    lat: 17.969877,
    lng: 102.612672,
    products: [],
  },
  {
    code: 'WAW',
    city: 'Warsaw',
    nameCn: '华沙',
    lat: 52.190552,
    lng: 20.921186,
    products: ['BMC_SERVER', 'SDN_VLL', 'SDN_CLOUD'],
  },
  {
    code: 'YLW',
    city: 'Kelowna',
    nameCn: '基隆拿',
    lat: 49.886199,
    lng: -119.441807,
    products: [],
  },
  {
    code: 'YYZ',
    city: 'Toronto',
    nameCn: '多伦多',
    lat: 43.801995,
    lng: -79.529545,
    products: [],
  },
  {
    code: 'ZAG',
    city: 'Zagreb',
    nameCn: '萨格勒布',
    lat: 45.803726,
    lng: 15.944355,
    products: [],
  },
  {
    code: 'ZRH',
    city: 'Zurich',
    nameCn: '苏黎世',
    lat: 47.2753,
    lng: 8.3257,
    products: ['VM_SERVER'],
  },
]

const vmMapData = cities.filter((i) => i.products.includes('VM_SERVER'))

const TopBanner = () => {
  return (
    <>
      <SolutionTopBanner
        className="vmTopBanner"
        {...static_data.topBanner}
        button={({ source, children }) => (
          <div className="buttonBox">
            <div className="mapBox">
              <Map
                mapData={vmMapData}
                id="zenlayer/clei4p1nm003s01qh1047ihk4"
                width={600}
                height={400}
              />
            </div>
            <Button
              size="l"
              className="white deployNowButton"
              onClick={() => {
                const link = `https://console.zenlayer.com/zenvm/vmCreate?promotion_tag=2210_bmc&utm_source=lp&utm_medium=web&utm_campaign=baremetal&partner=Zenlayer.&sale=TMqwoEuh`
                jumpToConsole(link)
              }}
            >
              {static_data.topBanner.buttonText}
            </Button>
            <Button
              size="l"
              onClick={() => {
                window.location.hash = ''
                window.location.hash = 'configure'
              }}
            >
              {static_data.topBanner.buttonText2}
            </Button>
          </div>
        )}
        // backgroundAnimated={true}
        bgColor="linear-gradient(180deg,rgba(242, 253, 247, 1) 0%,rgba(224, 249, 236, 1) 100%)"
        bgPic="/products/vm/topBanner-bg.svg"
        // extra={ map}
        //    animatedIcon="/products/bmc/animatedIcon.svg"
        //    iconBg="/products/bmc/iconBg.svg"
        source="vm"
        height={676}
        //   bannerPic={{
        //     src: `/products/bmc/topBanner-bg-m.svg`,
        //     style: {
        //       backgroundPosition: 'top center',
        //     },
        //   }}
      />

      {/**/}
    </>
  )
}

const B1 = () => {
  return (
    <div className="b1">
      <AnimationWrap>
        <div className="title">
          VMs use our 50+ Tbps network to help you connect to your users faster
        </div>
        <div className="content">
          <div className="left">
            <div className="description">
              The closer you are to your users, the faster their digital
              experience. Serve users from where they are using our ultra-low
              latency network – engineered with 45+ high-performance transit
              carriers, 5,500+ direct peers, and dynamic routing.
            </div>

            <div className="subtitle">
              Flexible billing methods to meet your needs:
            </div>

            <div className="tips">
              <span className="icon">✓</span> Flat rate (unlimited data
              transfer)
            </div>
            <div className="tips">
              <span className="icon">✓</span> Data transfer (burstable
              bandwidth)
            </div>
          </div>
          <div className="right">
            <img src="/products/vm/Latency.svg" alt="" />
          </div>
        </div>
      </AnimationWrap>
    </div>
  )
}

const B2 = () => {
  return (
    <div className="b2">
      <AnimationWrap>
        <div className="title">Spin up a VM in minutes</div>
        <div className="content">
          <div className="videoBox">
            <div className="cap" />
            <video
              muted
              controls
              poster="/products/vm/poster.png"
              className="video"
            >
              <source src="/products/vm/vm.mp4" type="video/mp4" />
            </video>
            <div className="abdomen" />
            <div className="bedPlate" />
          </div>
          <Button
            className="button"
            width={320}
            onClick={() => {
              jumpToSignup()
            }}
          >
            Try it out
          </Button>
        </div>
      </AnimationWrap>
    </div>
  )
}

const B3 = () => {
  const icons = [
    {
      src: '/products/vm/aws.svg',
      name: 'AWS',
    },
    {
      src: '/products/vm/google.svg',
      name: 'Google',
    },
    {
      src: '/products/vm/huawei.svg',
      name: 'HuaWei',
    },
    {
      src: '/products/vm/alibaba.svg',
      name: 'Alibaba',
    },
    {
      src: '/products/vm/IBM.svg',
      name: 'IBM',
    },
    {
      src: '/products/vm/tencent.svg',
      name: 'Tencent',
    },
    {
      src: '/products/vm/mic.svg',
      name: 'Microsoft Azure',
    },
    {
      src: '/products/vm/orac.svg',
      name: 'Oracle Clould',
    },
  ]
  return (
    <div className="b3">
      <AnimationWrap>
        <div className="content">
          <div className="left">
            <div className="title">
              Easily connect Zenlayer VMs to public clouds
            </div>
            <div className="description">
              Manage your data across public clouds with direct connections on
              our cloud networking service – enjoy high availability,
              low-latency, and zero packet loss on cross-regional connectivity
              via our private global backbone.
            </div>
            {/* <div className="subtitle">
              See how easy it is to create a multi-cloud{' '}
              <a
                onClick={() => {
                  const link = `https://console.zenlayer.com/sdn/router/create?promotion_tag=2210_bmc&utm_source=lp&utm_medium=web&utm_campaign=baremetal&partner=Zenlayer.&sale=TMqwoEuh`
                  jumpToConsole(link)
                }}
              >
                here
              </a>
            </div> */}
            <div className="iconsBox">
              {icons.map((i) => (
                <div className="icon" key={i.name}>
                  <img src={i.src} />
                  <span>{i.name}</span>
                </div>
              ))}
            </div>
            {/* <div className="tips">
              * Some cloud connections may be available only through offline
              provisioning
            </div> */}
          </div>
          {/* <div className="right">
            <img src="/products/vm/multiCloud.png" />
          </div> */}
        </div>
      </AnimationWrap>
    </div>
  )
}

const sortByTitle = (data: any) =>
  sort((a, b) => a.title.localeCompare(b.title), data)
const MTable = ({ data, columns }: any) => (
  <div className="mTable">
    {data.map((d, idx) => (
      <div className="mTable-item" key={d.instanceType}>
        {columns.map(({ title, dataIndex, render }) => (
          <div className="mTable-item-row" key={title}>
            <div className="mTable-label">{title}</div>
            <div className="mTable-value">
              {render ? render(d[dataIndex], d) : d[dataIndex]}
            </div>
          </div>
        ))}
      </div>
    ))}
  </div>
)
const Filter = () => {
  const [options, setOptions] = useState([])
  const [cityUuid, setCityUuid] = useState('')
  const [zoneUuid, setZoneUuid] = useState('')

  const [specs, setSpecs] = useState([])
  const [specUuid, setSpecUuid] = useState('')

  const [emptyText, setEmptyText] = useState<string>()

  const fetchRegion = async () => {
    try {
      const resp = await fetch('/api/vm/instance/describeAvailableRegionV2', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ chargeType: 'PREPAID' }),
      })
      if (!resp.ok) {
        throw new Error(`HTTP error! status: ${resp.status}`)
      }

      const data = await resp.json()
      // 这里可以添加对data的检查，例如检查是否是预期的格式或类型
      if (typeof data !== 'object') {
        throw new Error('Invalid data format!')
      }
      setOptions(
        data.data.regionResources.regions.map(({ title, name, cities }) => ({
          key: name,
          label: title,
          children: cities.map(({ title, uuid, zoneList }) => ({
            key: uuid,
            label: title,
            children: zoneList.map(({ zoneId, uuid }) => ({
              key: zoneId,
              label: zoneId,
            })),
          })),
        }))
      )
      // console.log(flatten(data.data.regions.map((r) => r.cities)))
      const city = sortByTitle(
        flatten(data.data.regionResources.regions.map((r) => r.cities))
      )[0]
      const zone = city.zoneList[0]
      fetchSpecs(city.uuid)
      setCityUuid(city.uuid)
      setZoneUuid(zone.zoneId)
    } catch (error) {
      setEmptyText(
        'zenConsole is currently unavailable due to system maintenance. We apologize for any inconvenience this may have caused.'
      )
    }
  }
  useEffect(() => {
    fetchRegion()
  }, [])

  const [fetchSpecsLoading, setFetchSpecsLoading] = useState(false)
  const fetchSpecs = async (region: string) => {
    setFetchSpecsLoading(true)
    try {
      const resp = await fetch(
        `/api/vm/instance/describeAvailableInstanceSpecs?regionId=${region}&instanceChargeType=PREPAID`
      )
      if (!resp.ok) {
        throw new Error(`HTTP error! status: ${resp.status}`)
      }
      const data = await resp.json()

      // 这里可以添加对data的检查，例如检查是否是预期的格式或类型
      if (typeof data !== 'object') {
        throw new Error('Invalid data format!')
      }
      const _specs =
        data.data.specs?.map((item) => {
          const p = item.price.prices?.sort(
            (a, b) => b.unitPrice - a.unitPrice
          )[1]
          return {
            ...item,
            price: {
              discountInfos: item.price?.discountInfos ?? [],
              originalPrice: p ? p.unitPrice : item.price.originalPrice,
              discountPrice: p ? p.discountPrice : item.price.discountPrice,
              unit: p?.chargeUnit,
              discount: p ? p.discount || 100 : item.price.discount || 100,
            },
          }
        }) ?? []
      setSpecs(_specs)
      setSpecUuid(_specs[0].instanceType)
      setFetchSpecsLoading(false)
    } catch (error) {
      setEmptyText(
        'zenConsole is currently unavailable due to system maintenance. We apologize for any inconvenience this may have caused.'
      )
    }
  }
  const columns = useMemo(
    () => [
      {
        title: 'Specification',
        dataIndex: 'instanceType',
        width: 120,
      },
      {
        title: 'Type',
        dataIndex: 'name',
        width: 90,
        render: (v: string | number, r) => {
          const nameValue = r.name.split(' ')
          nameValue.splice(nameValue.length - 1, 1)
          // debugger;
          const nameMap: { [key: string]: string } = {
            'Compute Optimized': 'Compute-optimized',
            'General Purpose': 'General-purpose',
            'Memory Optimized': 'Memory-optimized',
            'Shared vCPU': 'Basic compute',
            'High Performance': 'High-performance',
          }
          return (
            <div style={{ display: 'flex' }}>
              {nameMap[nameValue.join(' ')]}
            </div>
          )
        },
      },
      {
        title: 'TpKbGppymWoj',
        dataIndex: 'core',
        width: 92,
        render: (v: string | number) => {
          return `${v} VCPU`
        },
      },
      {
        title: 'Frequency',
        dataIndex: 'frequency',
        width: 95,
        render: (v: string | number) => {
          return `${v} GHz`
        },
      },
      {
        title: 'RAM',
        dataIndex: 'memory',
        width: 90,
        render: (v: string | number) => {
          return `${v} GB`
        },
      },
      {
        title: 'Price',
        dataIndex: 'price',
        width: 100,
        render: (v: string | number, r: any) => {
          return (
            <div>
              <Amount
                icon={<span style={{ transform: 'none' }}>$</span>}
                value={r.price?.discountPrice}
                unit={r.price?.unit}
                color="price"
              />
              {r.price?.discountPrice !== r.price?.originalPrice && (
                <Amount
                  icon={<span style={{ transform: 'none' }}>$</span>}
                  del
                  value={r.price?.originalPrice}
                  unit={r.price?.unit}
                />
              )}
            </div>
          )
        },
      },
    ],
    []
  )

  const mobileAction = {
    title: 'Action',
    dataIndex: '',
    render: (v, r) => (
      <div
        className="btn btn-s"
        onClick={() => {
          const link = `https://console.zenlayer.com/zenvm/vmCreate?promotion_tag=2210_bmc&utm_source=lp&utm_medium=web&utm_campaign=baremetal&partner=Zenlayer.&sale=TMqwoEuh&chargeType=prepaid&region=${cityUuid}&instanceType=${r.instanceType}`
          jumpToConsole(link)
        }}
      >
        Deploy Now
      </div>
    ),
  }

  return (
    <div className="filter">
      <div className="subtitle">Select location</div>
      <SelectLocation
        className="selectLocation"
        options={options}
        value={zoneUuid}
        onChange={(value, [region, city, zone]) => {
          setSpecs([])
          fetchSpecs(city.key)
          setCityUuid(city.key)
          setZoneUuid(zone.key)
        }}
      />

      <div className="subtitle">Choose specs</div>
      <div className="only-desktop">
        <Table
          columns={columns}
          rowKey="instanceType"
          dataSource={specs}
          scroll={{ y: 300 }}
          selection={{
            type: 'radio',
            selectedRowKeys: [specUuid],
            onChange: (key: any) => {
              setSpecUuid(key[0])
            },
          }}
          locale={{
            emptyText,
          }}
        />
      </div>
      <div className="only-mobile">
        <MTable columns={[...columns, mobileAction]} data={specs} />
      </div>

      <div className="deployBox">
        <Button
          onClick={() => {
            const link = `https://console.zenlayer.com/zenvm/vmCreate?promotion_tag=2210_bmc&utm_source=lp&utm_medium=web&utm_campaign=baremetal&partner=Zenlayer.&sale=TMqwoEuh&chargeType=prepaid&custom=true&region=${cityUuid}&instanceType=${specUuid}`
            jumpToConsole(link)
          }}
        >
          Deploy Now
        </Button>
        <span className="tips">
          Create an account on zenConsole to save your configuration
        </span>
      </div>
    </div>
  )
}

const B4 = () => {
  return (
    <div className="b4">
      <a id="configure" />
      <AnimationWrap>
        <div className="title">Configure and price</div>
        <div className="content">
          <Filter />
        </div>
      </AnimationWrap>
    </div>
  )
}

const Card = ({ title, desc, readMoreText, iconSrc, readMoreLink }) => {
  return (
    <div
      className="card"
      onClick={() => {
        window.open(readMoreLink)
      }}
    >
      <div className="top">
        <div className="icon">
          <img src={iconSrc} />
        </div>
        <div className="title">{title}</div>
        <div className="desc">{desc}</div>
      </div>
      <div className="bottom">{readMoreText}</div>
    </div>
  )
}
const B5 = () => {
  const data = [
    {
      iconSrc: '/products/vm/icon_VM.svg',
      title: 'Virtual machine overview',
      desc:
        'Learn about virtual elastic disks, virtual private clouds, and more.',
      readMoreText: 'Read more',
      readMoreLink:
        'https://docs.console.zenlayer.com/welcome/virtual-machine/overview',
    },
    {
      iconSrc: '/products/vm/icon_API.svg',

      title: 'API documentation',
      desc:
        'Using Zenlayer’s API, you can deploy and manage virtual machines with a few lines of code.',
      readMoreText: 'Learn more',
      readMoreLink:
        'https://docs.console.zenlayer.com/api-reference/vm/instance',
    },
    {
      iconSrc: '/products/vm/icon_news.svg',

      title: 'What’s new',
      desc:
        'We’re constantly adding new features and upgrading existing ones. Check out the latest!',
      readMoreText: 'Get updates',
      readMoreLink: 'https://www.zenlayer.com/what-is-new/?category=',
    },
  ]
  return (
    <div className="b5">
      <AnimationWrap>
        <div className="title">Resources</div>
        <div className="content">
          {data.map((i) => (
            <Card
              key={i.title}
              iconSrc={i.iconSrc}
              title={i.title}
              desc={i.desc}
              readMoreText={i.readMoreText}
              readMoreLink={i.readMoreLink}
            />
          ))}
        </div>
      </AnimationWrap>
    </div>
  )
}

const B6 = () => {
  return (
    <div className="b6">
      <div className="bgSvg" />

      <div className="content">
        <div className="title">Have more questions?</div>
        <ConsultationPopButton
          buttonWidth={400}
          source="index"
          buttonClassName="white calendarButton"
        >
          <div className="buttonContent">
            <img
              src="/products/vm/calendar.svg"
              width={24}
              height={24}
              className="icon"
            />
            Book a free consultation
          </div>
        </ConsultationPopButton>

        {/* <Button
            width={360}
            className="white emailButton"
            onClick={() => {
              window.location.href =
                'mailto:user@example.com?subject=Subject&body=message%20goes%20here'
            }}
          >
            <div className="buttonContent">
              <img
                src="/products/vm/email.svg"
                width={24}
                height={24}
                className="icon"
              />
              Send a message{' '}
            </div>
          </Button> */}
      </div>
    </div>
  )
}

export default function (data) {
  // const { allStrapiPosts, strapiTestimonials } = data
  console.log(data)
  return (
    <div className="virtualMachine">
      <SEO
        title="Zenlayer | Improve digital experiences. Instantly."
        description=""
        featuredImage="/featuredImage/zenlayer.jpg"
        noIndex={true}
      />
      <TopBanner />
      <B1 />
      <B2 />
      <B3 />
      <B4 />
      <B5 />
      <B6 />
      <Footer />
    </div>
  )
}
